import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import React, { FC, InputHTMLAttributes, useContext } from 'react'

type TextAreaFieldProps = {
	label?: string
} & InputHTMLAttributes<HTMLTextAreaElement>

const TextAreaField: FC<TextAreaFieldProps> = (props) => {
	const { pageData } = useContext<AppContextProps>(AppStateContext)
	return (
		<div>
			{props.label && (
				<label>{pageData.assets[props.label] ?? props.label}</label>
			)}
			<textarea {...props} placeholder="" />
		</div>
	)
}

export default TextAreaField
